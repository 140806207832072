import { AfterViewInit, Component, OnInit, ElementRef } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { Router, ActivatedRoute} from '@angular/router';
import { SchedaCorso } from '../../interface/scheda-corso';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import { ViewChild } from '@angular/core';
import { TabComponent, TabsetComponent } from 'ngx-tabset';
import { ReturnStatement } from '@angular/compiler';

import { JitsiComponent } from 'src/app/jitsi/jitsi.component';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { Accordion } from 'ngx-accordion';
import { element } from 'protractor';
import { timeInterval } from 'rxjs/operators';
import { settings } from 'cluster';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit {
    @ViewChild('staticTabs') staticTabs: TabsetComponent;
    @ViewChild('tabs2') tabs2: TabComponent;
    @ViewChild('tabs3') tabs3: TabComponent;
    @ViewChild('tabs4') tabs4: TabComponent;
    @ViewChild('tabs5') tabs5: TabComponent;
    @ViewChild('tabs6') tabs6: TabComponent;
    @ViewChild("myVideo", { static: false }) myVideo: ElementRef;
    @ViewChild('pageAccordion') pageAccordion: Accordion;

    //PAYPAL
    cartTotalPrice: number = 1;
    showPaypal: boolean = false;
    isTransactionComplete: boolean = false;

    public value_tempoUpdateReportEsame: number=10;
    public tempoUpdateReportEsame: number=this.value_tempoUpdateReportEsame;

    public logoPartner: string="assets/img/logo_new1.png";

    public idCorsoGlobal: string;
    public isMiur: boolean=false;
    public isMim: boolean=false;

    public costoCorso: string;
    public linkEsterno: string="";
    public costoCorsoScontato: string="";
    public productID: any;
    public detailAcquisto: any;
    public detailUser: any=[];

    public detailEsameCorsoSetting: any=[];

    public sessioneEsame: any=[];
    public sessioneEsameCorsista: any=[];
    public sessioneEsamePin: string="";
    public showEsameDiretto: boolean=false;

    public schedaCorsista: any;
    public moduliUC: string[];
    public moduloUC_TestValutazione: string[];

    public moduloUC_TestValutazioneSelected: any;
    public showTestValutazione: boolean=false;

    public materialeDidattico: string[];
    public nModuliUC: number;
    public ModuloUC_frequenza: number;

    public timeCheck: number;
    public timeCheckBackup: number;

    public tempoTrascorso: number = 0;
    public tempoRestanteLimite: number = 0;
    public tempoRestanteSessione: number = 0;
    public labelTempoRestanteLimite: string;
    public labelTempoRestanteSessione: string;

    public tempoRestanteLimiteTEST: number = 0;
    public labelTempoRestanteLimiteTEST: string;

    public arraySaveDatiUC_Tempo: Array<any>=[];
    public timerHandle: any;
    public timerHandleCheck: any;
    public timerHandleEsaminatore: any;
    public timeStartVideo: any;

    public nPropostaCorsi: any=[];
    public nParagrafi: number = 0;
    public elencoDataCorso: any=[];
    public detailQuiz: any=[];
    public detailQuizOnlyTEST: any=[];
    public showEsameFattiDay: boolean=false;
    public durataEsameTotale: string;

    public isEnabledUser: boolean=false;
    public isInLive: number=0;
    public protocollo: any;
    public mansione: any;
    public reportForData: number=0;
    public limita_moduloUC: number=0;
    public limiteGiorno: number=0;
    public limiteGiornoAula: number=0;
    public lockModuliUC: number=0;
    public isInLiveLinkAula: string="";
    public arrayLive: any=[];

    public detallCorso: SchedaCorso = {} as SchedaCorso;
    public urlImage = environment.urlImageCorso;
    public urlImageHD = environment.urlImageCorsoHD;
    public urlVideoCorso = environment.urlImageVideoCorso;
    public videoPreview = environment.urlImageVideoCorso;
    public materialeDidatticoPath = environment.urlMaterialeDidattico;
    public urlStampa = environment.urlStampa;

    public canShowElencoDate = environment.canShowElencoDate;

    public numeroTelefono = environment.numero_telefono;
    public canShowPagamentoRate = environment.canShowPagamentoRate;
    public textShowPagamentoRate = environment.textShowPagamentoRate;

    public isLimiteRaggiunto: boolean=false;

    public briciolaTag: string;
    public cntClick: number = 1;

    public showMeet: boolean=false;
    public showMeetPreload: boolean=false;

    public arrayConnessioniVideo: any=[];

    public isFAD_Always: boolean=false;

    constructor(
        private crudService: CrudService,
        private router: Router,
        private actRoute: ActivatedRoute,
        private metaService:Meta) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showEsame();
        }, 2000);
    }

   ngOnInit() {
    this.detallCorso['image']="";

    //mi recupero il dettaglio dell'user dopo il LOGIN
    this.detailUser=JSON.parse(localStorage.getItem('user_id'));

    if(!(this.detailUser)) {
        this.detailUser={'canDownloadAttestato': '0'};
    }

    //mi recupero l'ID del CORSO per ricavare la scheda
    this.productID = this.actRoute.snapshot.params['id'];

    if(this.detailUser) {
        this.crudService.api_getDetailCorsista(this.detailUser['id_corsista']).subscribe(productData => {
            //console.log(productData);

            this.detailUser['assistenza']=productData['assistenza'];
            this.detailUser['canDownloadAttestato']=productData['canDownloadAttestato'];
            this.schedaCorsista=productData;
            let idPartner=productData['id_partner'];

            this.crudService.api_get_DetailPartner(idPartner).subscribe(productData => {
                if(idPartner!=1) {
                    if(productData['image_logo'])
                        this.logoPartner=environment.urlImagePartners+productData['image_logo'];
                }

                this.urlVideoCorso+=this.detailUser['id_partner']+"/";
                this.videoPreview+=this.detailUser['id_partner']+"/";
                let idUser=this.detailUser['id_user'];
                this.mansione=this.detailUser['mansione'];
                let idCorsista=this.detailUser['id_corsista'];
                this.checkUserIsEnabled(this.productID, idCorsista);
            },(err)=>{
                //console.log(err);
                }, ()=>{
            }
            );

        },(err)=>{
            //console.log(err);
            }, ()=>{
        });
    }
    else {
        this.urlVideoCorso+="1/";
        this.videoPreview+="1/";
        this.loadDetailsCorso(this.productID, '1');
    }

    //this.loadDetailsCorso(this.productID);

     //visualizzo i dettaglio del CORSO. ritardo di 250 perchè prima mi serve capire se il corsista è abilitato
     /*
     setTimeout(() => {
     }, 500);
     */
  }

  showEsame() {
    /*
    if(this.tempoRestanteLimiteTEST>0 && this.detailQuiz['isSuperatoQuiz']==0) {
        this.staticTabs.tabs.first.active=false;
        //this.tabs3.active=false;
        if(this.tabs2.active) this.tabs2.active=false;
        if(this.tabs3.active) this.tabs3.active=false;
        this.tabs5.active=true;
    }
    */
  }

  playPause() {
    var myVideo: any = document.getElementById("myVideo");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  initiSocialMeta() {
    this.metaService.addTag( { name:'description',content:this.detallCorso.descrizione});
  }

  checkout(){
    if(this.detailUser) {
        this.showPaypal = true;
    }
    else {
        this.confermaNewAccount();
    }
  }

  confermaNewAccount() {
    Swal.fire({
        icon: 'info',
        title: 'Non sei registrato',
        text: 'prima di procedere bisogna registrare i propri dati, vuoi procedere con la registrazione?',
        showCancelButton: true,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, procedo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            location.href="/corsisti/nuovo-account";
        }
      })
  }

  annullaAcquisto() {
    this.showPaypal = false;
  }

  paymentApprove(event){
    //console.log(event);
    if(event.status == 'COMPLETED'){
        let idTransiction=event.id;
        let metodo="paypal";
        let status=event.status;
        let payer_id = event.payer.payer_id;
        let payer_nome=event.payer.name.given_name;
        let payer_cognome=event.payer.name.surname;
        let payer_email=event.payer.email_address;
        let purchase_id=event.purchase_units[0].payee.merchant_id;
        let purchase_importo_valuta=event.purchase_units[0].amount.currency_code;
        let purchase_importo=event.purchase_units[0].amount.value;
        let payment_id=event.purchase_units[0].payments.captures[0].id;

        var arrayDetail={'idCorso': this.idCorsoGlobal,
                         'idCorsista': this.detailUser['id_corsista'],
                         'idPartner': this.detailUser['id_partner'],
                         'metodo': metodo,
                         'idTransiction': idTransiction,
                         'status': status,
                         'payer_id': payer_id,
                         'payer_nome': payer_nome,
                         'payer_cognome': payer_cognome,
                         'payer_email': payer_email,
                         'purchase_id': purchase_id,
                         'purchase_importo_valuta': purchase_importo_valuta,
                         'purchase_importo': purchase_importo,
                         'payment_id': payment_id};

                         //console.log(arrayDetail);
      this.isTransactionComplete = true;
      this.registraPagamento(arrayDetail);
    }
    else {
        this.acquistoError();
    }
  }

  registraPagamento(arrayDetail) {
    this.crudService.api_registraPagamento(arrayDetail).subscribe(productData => {
        //console.log(productData);
        if(productData==1) {
            this.addCorsistaInAula();
        }
    },(err)=>{
        //console.log(err);
        }, ()=>{
    }
    );
  }

  addCorsistaInAula() {
    this.crudService.api_addCorsistaInAula(this.detailUser['id_corsista'], this.detailUser['id_partner'], this.idCorsoGlobal).subscribe(productData => {
        if(productData==1) {
            this.getDetailCorsista();
            this.acquistoSuccess();
        }
    },(err)=>{
        //console.log(err);
        }, ()=>{
    }
    );
  }

  getDetailCorsista() {
    this.crudService.api_getDetailCorsista(this.detailUser['id_corsista']).subscribe(productData => {

        //invio l'email di conferma attivare al corsista
        var arrayDetail={'nome': productData['nome'],
                        'cognome': productData['cognome'],
                        'nomeCorso': this.detallCorso.titolo,
                        'cf': productData['cf'],
                        'email': productData['email'],
                        'idPartner':this.detailUser['id_partner']};

        //invio l'email al corsista
        //this.sentEmailConfermaAttivazioneToCorsista(arrayDetail);
    },(err)=>{
        //console.log(err);
        }, ()=>{
    });
}

updateTimeReportEsame(saveFast) {
    this.tempoUpdateReportEsame-=1;
    if((this.tempoUpdateReportEsame<=0) || (saveFast==1)) {
        //se devo salvare in modo rapido, senza azzerare il contatore ma
        //salvando i secondi trascorsi
        if(saveFast==0)
            this.tempoUpdateReportEsame=this.value_tempoUpdateReportEsame;

        if((this.sessioneEsameCorsista.isDayEsame==1 &&
            this.sessioneEsameCorsista.isInLiveEsameCorsista==1 &&
            this.sessioneEsameCorsista.id_esame_type_documento &&
            this.sessioneEsameCorsista.n_documento &&
            (this.sessioneEsamePin!=this.sessioneEsameCorsista.pin_esame) &&
            this.showEsameDiretto==true) &&
            this.detailQuiz['isSuperatoQuiz']==0) {
                var arrayDetail={'idCorso': this.idCorsoGlobal,
                                'idCorsista': this.detailUser['id_corsista'],
                                'idQuizHeader':this.detailQuiz['id_quiz_header'],
                                'time': this.tempoUpdateReportEsame}
                this.crudService.api_updateTimeReportEsame(arrayDetail).subscribe(response => {
                    //console.log(response);
                });
        }
    }
}

aggiornaTempoLimiteTEST() {
    this.updateTimeReportEsame(0);

    if(this.tempoRestanteLimiteTEST<=0) {
        clearInterval(this.timerHandle);

        this.updateTimeReportEsame(1);

        //this.tabs5.active=true;
        if(this.sessioneEsameCorsista.isDayEsame==1 && this.sessioneEsameCorsista.isInLiveEsameCorsista==1 && this.showEsameDiretto==true) {
            this.controllaQuizTEST(1);
        }
        else if(this.detailQuiz['terminaTEST']) {
            this.controllaQuizTEST(0);
        }
        else {
            if(this.timerHandle)
                location.reload();
        }

        //location.reload();
        return;
    }

    this.tempoRestanteLimiteTEST-=1;
    this.labelTempoRestanteLimiteTEST=this.secondsToHms(this.tempoRestanteLimiteTEST);
 }

  aggiornaTempoLimite() {
    if(this.tempoRestanteLimite<=0) {
        return;
    }

    this.tempoRestanteLimite-=1;
    this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);

    if(this.tempoRestanteLimite<=0) {
        if(this.tempoTrascorso<=0)
            location.reload();

        clearInterval(this.timerHandle);
        this.chiudiVideoLimiteRaggiunto();
    }
  }

  aggiornaTempoSessione() {
    if(this.tempoRestanteSessione<=0) {
        return;
    }

    this.tempoRestanteSessione-=1;
    this.labelTempoRestanteSessione=this.secondsToHms(this.tempoRestanteSessione);

    if(this.tempoRestanteSessione<=0) {
        if(this.tempoTrascorso<=0)
            location.reload();

        clearInterval(this.timerHandle);
        this.chiudiVideoLimiteRaggiunto();
    }
  }

  aggiornaTempoLimiteAula() {
    this.tempoRestanteLimite-=1;
    this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);

    if(this.tempoRestanteLimite<=0) {
        this.limiteGiornoAula=0;
        this.isInLive=2;
        this.setTimeOutAulaForCorsisti();
        clearInterval(this.timerHandle);
    }
  }

  setTimeOutAulaForCorsisti() {
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorsista=this.detailUser['id_corsista'];

    this.crudService.api_setTImeoutCorsista(idCorsista, idCorsoSvolgimento).subscribe(productData => {
        this.showMeet=false;
    },(err)=>{
        //console.log(err);
        }, ()=>{
    });

    Swal.fire({
        title:'Evento Terminato',
        text: 'puoi uscire dall\'aula.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });

    setTimeout(() => {
        location.reload();
    }, 5000);
  }

  selectAula() {
    this.staticTabs.tabs.first.active=false;
    this.staticTabs.tabs.last.active=true;
    this.tabs2.active=false;
  }


  selectTabModuliUC(canAdd) {
    this.staticTabs.tabs.first.active=true;
    this.staticTabs.tabs.last.active=false;
    if(this.tabs2) this.tabs2.active=false;
    if(this.tabs3) this.tabs3.active=false;
    if(this.tabs4) this.tabs4.active=false;
    if(this.tabs5) this.tabs5.active=false;

    if(canAdd)
        this.entraInAula();
  }

  acquistoSuccess() {
    let name=this.schedaCorsista['cognome']+" "+this.schedaCorsista['nome'];
    let email="egeria.formazione@gmail.com";
    let number=this.schedaCorsista['telefono'];
    let message="Corso: <b>"+this.detallCorso.titolo+"</b> al costo di <b>€. "+this.cartTotalPrice+"</b>";
    let sceltaCorso='<b>Acquisto Online</b>';
    this.crudService.sent_Email(name, email, number, message, sceltaCorso).subscribe(responseData => {
        },(err)=>{
            }, ()=>{
        }
    );

    Swal.fire({
        title:'Pagamento Effettuato',
        text: 'effettuato con successo, puoi accedere al corso.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Iniza il corso',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }


  acquistoError() {
    Swal.fire({
        title:'Pagamento non riuscito!',
        text: 'non siamo riusciti a completare il pagamento.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Riprova',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }

  noFADAttiva(isLocked) {
    if(isLocked==2) {
        Swal.fire({
            title:'Modulo incompleto',
            text: 'prima di procedere alla visualizzazione del materiale del modulo UC attuale devi completare la visualizzazione dei moduli precedenti.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Riprova',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {
                location.reload();
            }
        });
        return;
    }

    Swal.fire({
        title:'FAD non attiva!',
        text: 'non sei abilitato alla visualizzazione del corso in questo giorno o fascia d\'orario, consulta l\'elenco date',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  noAulaAttiva() {
    Swal.fire({
        title:'Aula non attiva!',
        text: 'non è stato ancora creato il link per connetterti in aula virtuale, riprova più tardi.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  entraInAula() {
     if(this.arrayLive.length<=0) {
         return;
     }

      let arrayDetails={'idCorsista':this.arrayLive['id_corsista'],
                        'idCorsoSvolgimento': this.arrayLive['id_corso_svolgimento'],
                        'idCorsoHeader':this.arrayLive['id_corso_header'],
                        'durata': this.arrayLive['durata'],
                        'nome_aula':this.arrayLive['nome_aula'],
                        'link_aula':this.arrayLive['link_aula'],
                        'dataInizio': this.arrayLive['data_ora'],
                        'dataFine': this.arrayLive['data_ora_fine_full']};
      this.crudService.set_entraInAulaCorsista(arrayDetails).subscribe(responseData => {
         // this.showMeet=true;
          if(responseData==1) {}
      });
    }

   entraInAulaMeet() {
        if(this.arrayLive.length<=0) {
            return;
        }

        this.showMeet=true;
        this.showMeetPreload=true;

        setTimeout(() => {
            var tk=document.getElementById('jitsi-iframe') as HTMLInputElement;
            tk.style.display="block";

            this.showMeetPreload=false;
        }, 2000);



        /*
         let arrayDetails={'idCorsista':this.arrayLive['id_corsista'],
                           'idCorsoSvolgimento': this.arrayLive['id_corso_svolgimento'],
                           'idCorsoHeader':this.arrayLive['id_corso_header'],
                           'durata': this.arrayLive['durata'],
                           'nome_aula':this.arrayLive['nome_aula'],
                           'link_aula':this.arrayLive['link_aula'],
                           'dataInizio': this.arrayLive['data_ora'],
                           'dataFine': this.arrayLive['data_ora_fine_full']};
         this.crudService.set_entraInAulaCorsista(arrayDetails).subscribe(responseData => {
             this.showMeet=true;

             var tk=document.getElementById('jitsi-iframe') as HTMLInputElement;
             tk.style.display="block";

             if(responseData==1) {}
         });
         */
    }

  openLinkografia(url) {
    if(!url){
        return null;
      } else{
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
      }
  }

  loadDetailsCorso(idCorso, isOnlyDAD){
    var idCorsista='';
    if(this.detailUser)
        idCorsista=this.detailUser['id_corsista'];

    this.crudService.api_get_DetailCorso(idCorso, idCorsista, this.reportForData).subscribe(product => {
        //console.log(product);
        let idCorso=product['id_corso'];
        this.isMiur=product['isMiur'];
        this.isMim=product['isMim'];
        this.idCorsoGlobal=product['id_corso'];

        this.detailEsameCorsoSetting=product['esame_corso'];

        let durataLabel=product['durata']['durata_label']=='h' ? 'ore' : product['durata']['durata_label'];
        this.detallCorso={
            durataNumero: product['durata']['durata_numero'],
            durata: product['durata']['durata_numero']+' ' +durataLabel,
            titolo: product['titolo'],
            descrizione: product['descrizione'],
            categoria: product['sottocategoria_corsi'][0],
            coin: product['numero_crediti_corsista_value'],
            costo: parseFloat(product['costo']),
            cfu: product['cfu'],
            image: product['image'],
            programma: product['programma'],
            moduliUC: product['moduloUC'],
            tokenMeet: product['tokenMeet'],
            haveBuy: product['haveBuy']
        };
        //console.log(this.detallCorso);

        if(this.arrayLive.hasOwnProperty('isToday')==false) {
            //console.log(this.detallCorso);
            //console.log(this.arrayLive);

            if(this.detallCorso['haveBuy']) {
                this.lockModuliUC=0;
                this.isInLive=1;
            }
        }

        this.costoCorso=parseFloat(product['costo']).toFixed(2).toString().replace(".",",");
        this.linkEsterno=product['link_esterno'];
        this.cartTotalPrice=parseFloat(product['costo']);

        if(parseFloat(product['costo_scontato'])>0) {
            this.costoCorsoScontato=parseFloat(product['costo_scontato']).toFixed(2).toString().replace(".",",");
            this.cartTotalPrice=parseFloat(product['costo_scontato']);
        }

        this.timeCheck=product['time_check'];

        if(this.isInLive==1 && (this.arrayLive['time_check']==1)) {
            if(this.timeCheck>0) {
                this.timeCheck=this.timeCheck*60;
                this.timeCheckBackup=this.timeCheck;
                this.timerHandleCheck=setInterval(() => {
                    this.countTimeCheck();
                }, 1000);
            }
        }
        /*
        fetch(environment.urlImageCorso+"scheda/"+product['id_corso']+'.txt')
            .then(response => response.text())
            .then(data => {
  	            // Do something with your data
  	            console.log(data);
            }
        );
        */
        document.getElementById("programmaHTML").innerHTML=product['programma'];
        this.ModuloUC_frequenza=product['ModuloUC_frequenza'];
        this.nModuliUC=product['moduloUC'].length;
        this.nPropostaCorsi=product['propostaCorsi'];

        if(product['moduloUC'].length)
            this.videoPreview+=product['moduloUC'][0]['paragrafi'][0]['sotto-paragrafi'][0]['video'];

        product['moduloUC'].forEach(element => {
            this.nParagrafi+=element['paragrafi'].length;
        });
        this.moduliUC=product['moduloUC'];

        //controllo se l'amministratore ha attivato: PROGRESSIONE DEI CONTENUTI
        var isProgressioneModuli=0;
        if(this.elencoDataCorso.length) {
            isProgressioneModuli=this.elencoDataCorso[0]['canProgressiveModuli'];
        }

        //controllo se l'amministratore ha deciso di attivare il FLAG "isSequenzaModuliUC"
        //in tal caso mostro SOLO i moduli UC inseriti all'interno dell'evento
        if(this.isInLive) {
            if(this.arrayLive.isLive==1) {
                if(this.arrayLive.isSequenzaModuliUC==1) {
                    var newArray=new Array();
                    var indexElement=0;
                    this.moduliUC.forEach(element => {
                        element['isLocked']=1;
                        this.arrayLive.isSequenzaModuliUC_Array.forEach(elementSequenza => {
                            if(element['id_modulo_header']==elementSequenza.id_modulo_header) {
                                //onsole.log(indexElement+"---"+element['id_modulo_header']+"---"+element['isComplete']+"---"+element['titolo']);
                                if(indexElement==0) {
                                    element['isLocked']=0;
                                }
                                else if(indexElement>0) {
                                    if(this.moduliUC[indexElement-1]['isComplete']==1)
                                        element['isLocked']=0;
                                    else {
                                        //modulo precedente non completo
                                        element['isLocked']=2;
                                    }
                                }
                                indexElement++;
                            }
                        });
                        newArray.push(element);
                    });
                    this.moduliUC=newArray;
                }
                else if(isProgressioneModuli==1) {
                    var newArray=new Array();
                    var indexElement=0;
                    this.moduliUC.forEach(element => {
                        if(indexElement==0) {
                            element['isLocked']=0;
                        }
                        else if(indexElement>0) {
                            if(this.moduliUC[indexElement-1]['isComplete']==1)
                                element['isLocked']=0;
                            else {
                                //modulo precedente non completo
                                element['isLocked']=2;
                            }
                        }
                        newArray.push(element);
                        indexElement++;
                    });

                    this.moduliUC=newArray;
                }
            }

            if(this.arrayLive.isLive==3) {
                if(this.arrayLive.isSequenzaModuliUC==1 || isProgressioneModuli==1) {
                    var newArray=new Array();
                    var indexElement=0;
                    this.moduliUC.forEach(element => {
                        if(indexElement==0) {
                            element['isLocked']=0;
                        }
                        else if(indexElement>0) {
                            if(this.moduliUC[indexElement-1]['isComplete']==1)
                                element['isLocked']=0;
                            else {
                                //modulo precedente non completo
                                element['isLocked']=2;
                            }
                        }
                        newArray.push(element);
                        indexElement++;
                    });

                    this.moduliUC=newArray;
                }
            }
        }

        //controllo se nell'evento in LIVE (FAD) è stato attivato il flag: "isSequenzaModuliUC"
        //in questo caso faccio vedere SOLO il modulo UC abilitato per la FAD, abilitato per l'evento scelto
        if(this.ModuloUC_frequenza<100) {
            this.ModuloUC_frequenza=100;
            this.moduliUC.forEach(elementUC => {
                elementUC['paragrafi'].forEach(elementSotto => {
                    elementSotto['sotto-paragrafi'].forEach(element => {
                        if(element['haveReadPDF']==0) {
                            this.ModuloUC_frequenza=0;
                            return false;
                        }
                    });
                });
            });
        }

        //controllo dai setting se l'esame deve essere visualizzato SOLO nelle sessioni d'esame oppure NO
        if(this.detailEsameCorsoSetting['isEsameOnlySessione']==1)
                this.ModuloUC_frequenza=0;

        this.moduloUC_TestValutazione=product['moduloUC_TestValutazione'];
        //console.log(this.moduloUC_TestValutazione);

        if(this.moduloUC_TestValutazioneSelected) {
            this.showTestValutazione=true;
        }

        this.materialeDidattico=product['materiale_didattico'];

        //this.checkUserIsEnabled(idCorso, idCorsista);

        this.detailAcquisto=product['haveBuyDetail'];
        if(this.detailAcquisto.length<=0)
            this.detailAcquisto="";


        //effettuo diversi controlli
        if(this.arrayLive['isLive']!=1) {
            if(this.isFAD_Always==true) {
                this.lockModuliUC=0;
                this.reportForData=1;
                this.isInLive=1;
                this.limita_moduloUC=1;
            }
        }

        //se è stata ATTIVATA la funzione FAD SEMPRE ATTIVA allora sblocco la FAD
        if(this.isFAD_Always==true) {
            this.lockModuliUC=0;
            this.isInLive=1;
        }
        //indica che il corsista ha acquistato il corso, quindi non ha eventi, il corso è LIBERO
        else if((this.arrayLive.hasOwnProperty('isToday')==false) || (this.arrayLive['isToday']==0)) {
            if(this.detallCorso['haveBuy'] && isOnlyDAD) {
                this.lockModuliUC=0;
                this.isInLive=1;
            }
        }

        if(this.detailUser) {
            //getElencoSessioniEsameFrontEnd
            this.crudService.api_getElencoSessioniEsameFrontEnd(this.detailUser.id_corsista, this.idCorsoGlobal).subscribe(response => {
                console.log(response);
                this.sessioneEsame=response;
            });

            this.crudService.api_getSessioneEsameCorsista(this.detailUser.id_corsista, idCorso).subscribe(response => {
                this.sessioneEsameCorsista=response;
                //console.log(this.sessioneEsameCorsista);

                if(this.sessioneEsameCorsista.secondiInizioSessione>0) {
                    setTimeout(() => {
                        this.refreshStatusInizioEsame();
                    }, (parseInt(this.sessioneEsameCorsista.secondiInizioSessione)+1)*1000);
                }

                if((this.sessioneEsameCorsista.isDayEsame==1 && this.sessioneEsameCorsista.isInLiveEsameCorsista==1) && !(this.sessioneEsameCorsista.id_esame_type_documento && this.sessioneEsameCorsista.n_documento)) {
                    this.timerHandleEsaminatore=setInterval(() => {
                        this.refreshStatusEsaminatoreDocumenti();
                    }, 10000);
                }

                setTimeout(() => {
                    if(this.sessioneEsameCorsista.isDayEsame==1 && this.sessioneEsameCorsista.isInLiveEsameCorsista==1 && this.detailQuiz['isSuperatoQuiz']==0) {
                        if(this.detailQuiz.time_esame_secondi>0) {
                            //tolgo il tempo trascorso per fare in precedenza, cadute di connessione, etc....dal tempo totale del test a disposizione
                             this.tempoRestanteLimiteTEST=((this.detailQuiz.time_esame_secondi+1)-this.detailQuiz.tempoTrascorso);
                             this.aggiornaTempoLimiteTEST();
                        }
                        else {
                            this.tempoRestanteLimiteTEST=this.sessioneEsameCorsista.secondiFineSessione;
                            this.timerHandle=setInterval(() => {
                                this.aggiornaTempoLimiteTEST();
                            }, 1000);
                        }
                    }
                }, 250);

                //mi ricavo la lista degli esami presenti all'interno del corso
                let idCorsoSvolgimento=(this.arrayLive['id_corso_svolgimento']);
                this.crudService.api_get_DetailQuiz(idCorso, idCorsista, idCorsoSvolgimento).subscribe(product => {
                    console.log(product);
                    console.log(this.sessioneEsameCorsista);
                    //console.log(product['quiz'].length);

                    //controllo se sono stati selezionati deggli esami per la sessione d'esame
                    //che deve sostenere
                    if(this.sessioneEsameCorsista.sessioneEsamePrenotato?.length) {
                        var arrayNewQuiz=new Array();
                        //visualizzo solo per la sessione d'esame SOLO gli esami che sono stati scelti
                        product['quiz'].forEach(elementQuiz => {
                            this.sessioneEsameCorsista.sessioneEsamePrenotato.forEach(elementSessione => {
                                if(((elementSessione['id_quiz_header']==elementQuiz['id_quiz_header']) && this.sessioneEsameCorsista.isDayEsame==1)) {
                                    arrayNewQuiz.push(elementQuiz);
                                    //console.log(elementQuiz);
                                }
                            });
                        });
                        //aggiorno la lista dei TEST con quelli scelti per la sessione d'esame
                        if(arrayNewQuiz.length)
                            product['quiz']=arrayNewQuiz;
                    }

                    console.log(product['quiz']);

                    var dataSessione="";
                    if(this.sessioneEsameCorsista?.hasOwnProperty('data_ora_esame'))
                        dataSessione=this.sessioneEsameCorsista.data_ora_esame.split(' ')[0];
                    var dataTestEffettuato="";

                    //controllo la variabile, setting per il corso impostato, quanti N esami per volta
                    //si possono fare (n_esami_max), logicamente NON conteggiando quelli superati
                    var indexQuizEsame=0;
                    var nEsameMax=0;
                    if(this.sessioneEsameCorsista.settingEsameCorso?.hasOwnProperty('n_esami_max'))
                        nEsameMax=this.sessioneEsameCorsista.settingEsameCorso.n_esami_max;

                    if(product['quiz'].length>=nEsameMax) {
                        //console.log(nEsameMax);
                        var arrayNewQuiz=new Array();

                        product['quiz'].forEach(element => {
                            //console.log(element);
                            //se l'esame è stato superato lo metto solo nella visualizzazione ma no nel conteggio degli
                            //esami da sostenere
                            if(element.isSuperatoQuiz==1) {
                                dataTestEffettuato=element.quizEffettuati[0].data_create.split(' ')[0];
                                if(dataSessione==dataTestEffettuato) {
                                    arrayNewQuiz.push(element);
                                    indexQuizEsame++;
                                }
                            }
                            if(element.isSuperatoQuiz==0) {
                                //console.log(nEsameMax+"---"+indexQuizEsame);

                                if(nEsameMax>indexQuizEsame) {
                                    if(element.quizEffettuati.length) {
                                        dataTestEffettuato=element.quizEffettuati[0].data_create.split(' ')[0];
                                    }
                                    //console.log(dataSessione+"---"+dataTestEffettuato);
                                    if(dataSessione!=dataTestEffettuato) {
                                        element['quizEffettuati']=new Array();
                                    }
                                    arrayNewQuiz.push(element);
                                    indexQuizEsame++;

                                }
                            }
                        });

                        if(arrayNewQuiz.length)
                            product['quiz']=arrayNewQuiz;
                        //console.log(arrayNewQuiz);
                    }

                    this.detailQuizOnlyTEST=product['quiz'];
                    this.durataEsameTotale=product['timeEsameTotaleFormattato'];

                    //visualizzo quanti esami ci sono all'interno del corso
                    //console.log(this.detailQuizOnlyTEST);

                    //inizializzo il QUIZ che deve affrontate il corsista
                    //se ha effettuato tutti i quiz l'array detailQuiz è vuoto, quindi non visualizza nulla da fare, perchè tutti
                    //gli esami sono stati fatti
                    var isSelectedQuiz=0;
                    product['quiz'].forEach(element => {
                        if(element['quizEffettuati'].length==0 && isSelectedQuiz==0) {
                            this.detailQuiz=element;
                            //if(dataSessione==dataTestEffettuato) {
                                isSelectedQuiz=1;
                            //}
                        }
                    });

                    //significa che sono i quiz ed ha effettuato tutti gli esami
                    if(product['quiz'].length && isSelectedQuiz==0) {
                        //this.detailQuiz=product['quiz'][0];
                        this.showEsameFattiDay=true;
                    }

                    //this.detailQuiz=product['quiz'][0];
                    //console.log(this.detailQuiz);

                    this.detailQuiz['corsista']=product['corsista'];
                    this.detailQuiz['id_corso']=idCorso;

                    if(this.detailQuiz['terminaTEST']>1) {
                        if(this.detailQuiz['isSuperatoQuiz']==0) {
                            this.tempoRestanteLimiteTEST=this.detailQuiz['terminaTEST'];

                            this.timerHandle=setInterval(() => {
                                this.aggiornaTempoLimiteTEST();
                            }, 1000);
                        }
                    }

                    /*
                    console.log(this.sessioneEsame);
                    console.log(this.isEnabledUser);
                    console.log(this.detailQuiz['isDataLimitata']==0);
                    console.log(this.sessioneEsameCorsista.isInLiveEsameCorsista!=1);
                    console.log(this.detailQuiz['isSuperatoQuiz']==0);
                    console.log(this.detailQuiz);
                    */
                });
            });
        }

        //console.log(this.arrayLive);

        //inizializzo i meta tag per le gestione social
        this.initiSocialMeta();

    });
  }

  refreshStatusInizioEsame() {
    //getElencoSessioniEsameFrontEnd
    this.crudService.api_getSessioneEsameCorsista(this.detailUser.id_corsista, this.idCorsoGlobal).subscribe(response => {
        this.sessioneEsameCorsista=response;
        if((this.sessioneEsameCorsista.isDayEsame==1 && this.sessioneEsameCorsista.isInLiveEsameCorsista==1) && !(this.sessioneEsameCorsista.id_esame_type_documento && this.sessioneEsameCorsista.n_documento)) {
            this.timerHandleEsaminatore=setInterval(() => {
                this.refreshStatusEsaminatoreDocumenti();
            }, 10000);
        }

        setTimeout(() => {
            if(this.sessioneEsameCorsista.isDayEsame==1 && this.sessioneEsameCorsista.isInLiveEsameCorsista==1 && this.detailQuiz['isSuperatoQuiz']==0) {
                if(this.detailQuiz.time_esame_secondi>0) {
                    this.tempoRestanteLimiteTEST=this.detailQuiz.time_esame_secondi;
                }
                else {
                    this.tempoRestanteLimiteTEST=this.sessioneEsameCorsista.secondiFineSessione;
                }
                this.timerHandle=setInterval(() => {
                    this.aggiornaTempoLimiteTEST();
                }, 1000);

                Swal.fire({
                    title: 'Inizio Sessione d\'esame',
                    text: "è iniziata la sessione d\'esame.",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Chiudi',
                    cancelButtonText: 'Annulla'
                  }).then((result) => {
                    if (result.isConfirmed) {
                    }
                })
            }
        }, 250);


    });
  }

  refreshStatusEsaminatoreDocumenti() {
    this.crudService.api_getSessioneEsameCorsista(this.detailUser.id_corsista, this.idCorsoGlobal).subscribe(response => {
        this.sessioneEsameCorsista=response;

        if((this.sessioneEsameCorsista.isDayEsame==1 && this.sessioneEsameCorsista.isInLiveEsameCorsista==1) && (this.sessioneEsameCorsista.id_esame_type_documento && this.sessioneEsameCorsista.n_documento)) {
            this.tempoRestanteLimiteTEST=this.sessioneEsameCorsista.time_esame_secondi;
            clearInterval(this.timerHandleEsaminatore);
            Swal.fire({
                title: 'Documenti convalidati',
                text: "l'esaminatore ha approvato i tuoi documenti, puoi procedere con la sessione d'esame.",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Inizia l\'esame',
                cancelButtonText: 'Annulla'
              }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        }
    });
  }



  GetAllSelectRadioButtons() {
    var arrInput = document.getElementsByTagName("input"), arrRadio = [];
    var j = 0;
    for (var i = 0; i < arrInput.length; i++) {
           if (arrInput[i].type == "radio" && arrInput[i].checked) {
                //var arrayDetail={'value': arrInput[i].value, 'name':arrInput[i].name};
                  arrRadio[j] = arrInput[i];
                  j++;
           }
    }
    return arrRadio;
}

GetAllRispostaMultipla() {
    var arrInput = document.getElementsByTagName("textarea"), arrRadio = [];
    var j = 0;
    for (var i = 0; i < arrInput.length; i++) {
           //if (arrInput[i].type == "radio" && arrInput[i].checked) {
                //var arrayDetail={'value': arrInput[i].value, 'name':arrInput[i].name};
                  arrRadio[j] = arrInput[i];
                  j++;
           //}
    }
    return arrRadio;
}

GetAllSelectRadioButtonsTEST() {
    var arrInput = document.getElementsByTagName("input"), arrRadio = [];
    var j = 0;
    for (var i = 0; i < arrInput.length; i++) {
        if (arrInput[i].type == "radio") {
            //var idRisposta="";
            //if(arrInput[i].checked)
                //idRisposta=arrInput[i].checked.;
            //var arrayDetail={'value': arrInput[i].value, 'name':arrInput[i].name};
            arrRadio[j] = arrInput[i];
            j++;
        }

    }
    return arrRadio;
}

 controllaQuizTEST(isFromSessioneEsame) {
    var arrayQuiz=this.GetAllSelectRadioButtonsTEST();
    var arrayRisposte=new Array();

    let idQuizHeader=this.detailQuiz['id_quiz_header'];
    let idCorsista=this.detailUser['id_corsista'];
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorso=this.detailQuiz['id_corso'];

    //mi serve per la gestione della generazione dell'attestato
    var arrayDetail={'id_corso': idCorso, 'id_corsista': idCorsista, 'id_corso_svolgimento': idCorsoSvolgimento};

    var domandaPrecedente="";
    var risposta="";
    var initIndex=0;
    var domanda="";

    if(arrayQuiz.length>0)
        domandaPrecedente=arrayQuiz[0].name;


    //raggruppo l'array in base alle domande
    arrayQuiz = arrayQuiz.reduce(function (r, a) {
        r[a.name] = r[a.name] || [];
        r[a.name].push(a);
        return r;
    }, Object.create(null));

    //controllo se si tratta di un esame con la SESSIONE D'ESAME impostazione con le date
    var idEsameBody="";
    if(isFromSessioneEsame==1) {
        idEsameBody=this.sessioneEsameCorsista['id_esame_body'];
    }

    var isRispostaLibera="";
    for (var key in arrayQuiz) {
        var value = arrayQuiz[key];
        risposta="";
        value.forEach(element => {
            if(element.checked==true) {
                risposta=element.value;
                isRispostaLibera=element.getAttribute('isRispostaLibera');
            }
        });

        arrayRisposte.push({"idEsameBody": idEsameBody,
                            "id_corso":idCorso,
                            "id_header":idQuizHeader,
                            "id_corsista":idCorsista,
                            "id_corso_svolgimento":idCorsoSvolgimento,
                            "id_domanda":key,
                            "id_risposta":risposta,
                            "isTestValutazione": '0',
                            "isRispostaLibera": isRispostaLibera});
    };

    document.getElementById('btnTest').remove();

    this.crudService.api_verifica_quiz(arrayRisposte).subscribe(product => {
        //console.log(product);
        let corrette=product['corrette'];
        let nonCorrette=product['non_corrette'];
        let numeroDomande=product['numero_domande'];
        let sogliaMinimaPercentuale=product['soglia_minima_percentuale'];
        let sogliaMinimaDomande=product['soglia_minima_domande'];
        let sogliaCorrettePercentuale=product['soglia_corrette_percentuale'];
        if(corrette>=sogliaMinimaDomande) {
            if(this.detailUser['canDownloadAttestato']==0) {
                //test superato
                this.quizSuperato(corrette, numeroDomande);
                return;
            }

            //genero l'attestato
            this.crudService.api_GeneraAttestato(arrayDetail).subscribe(product => {
                //console.log(product);
                let nomeCorsista=product['nome_corsista'];
                let data_nascita=product['data_nascita'];
                let provincia_nascita=product['provincia_nascita'];
                let comune_nascita=product['comune_nascita'];
                let data_attestato=product['data_attestato'];
                let cf=product['cf'];
                let codiceAteco=product['codice_ateco'];
                let isPresent=product['isPresent'];
                let attestato=product['attestato'];
                let dataStart=product['dataStart'];
                if(isPresent.hasOwnProperty('id_certificato')) {
                    window.open(environment.urlAttestati+isPresent.file);

                    setTimeout(() => {
                        //test superato
                        this.quizSuperato(corrette, numeroDomande);
                    }, 250);
                }
                else {
                    if(attestato) {
                        var detail='?id_corso='+idCorso+'&id_corso_svolgimento='+idCorsoSvolgimento+'&id_corsista='+idCorsista+'&nome_corsista='+nomeCorsista+'&data_nascita='+data_nascita+'&provincia_nascita='+provincia_nascita+'&comune_nascita='+comune_nascita+'&data_attestato='+data_attestato+'&dataStart='+dataStart+'&cf='+cf+'&protocollo='+this.protocollo+'&mansione='+this.mansione+'&codiceAteco='+codiceAteco;
                        window.open(environment.urlAttestatiDaCreare+attestato+".php"+detail);

                        setTimeout(() => {
                            //test superato
                            this.quizSuperato(corrette, numeroDomande);
                        }, 250);
                    }
                    else {
                        Swal.fire({
                            title: 'Errore!',
                            text: "non è stasto creato l\'attestato .pdf, non posso procedere alla generazione dell\'attestato",
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Risolvi',
                            cancelButtonText: 'Annulla'
                          }).then((result) => {
                            if (result.isConfirmed) {
                            }
                        })
                    }
                }
            });
        }
        else {
            //test non superato
            this.quizNonSuperato(corrette, nonCorrette);
        }

    });
 }

  entraInMeetEsame(idEsameBody) {
    var arrayDetail={'idCorsista': this.detailUser.id_corsista, 'idEsameBody': idEsameBody, 'tipologia': 'in_meet_esame'};
    this.crudService.set_entraInMeetEsame(arrayDetail).subscribe(response => {
        //console.log(response);
    });
  }

  controllaQuiz() {
    var arrayQuiz=this.GetAllSelectRadioButtons();
            var arrayQuizRispostaLibera=this.GetAllRispostaMultipla();
            arrayQuiz=arrayQuiz.concat(arrayQuizRispostaLibera);
            var arrayRisposte=new Array();

            let idQuizHeader=this.detailQuiz['id_quiz_header'];
            let idCorsista=this.detailUser['id_corsista'];
            let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
            let idCorso=this.detailQuiz['id_corso'];

            //mi serve per la gestione della generazione dell'attestato
            var arrayDetail={'id_corso': idCorso, 'id_corsista': idCorsista, 'id_corso_svolgimento': idCorsoSvolgimento};

            //controllo se si tratta di un esame con la SESSIONE D'ESAME impostazione con le date
            var idEsameBody="";
            if(this.sessioneEsameCorsista.hasOwnProperty("id_esame_body")) {
                idEsameBody=this.sessioneEsameCorsista['id_esame_body'];
            }

            arrayQuiz.forEach((element) => {
                var domanda=element.name;
                var risposta=element.value;
                var isRispostaLibera=element.getAttribute('isRispostaLibera');
                if(domanda)
                    arrayRisposte.push({"idEsameBody": idEsameBody,
                                        "id_corso":idCorso,
                                        "id_header":idQuizHeader,
                                        "id_corsista":idCorsista,
                                        "id_corso_svolgimento":idCorsoSvolgimento,
                                        "id_domanda":domanda,
                                        "id_risposta":risposta,
                                        'isTestValutazione': '0',
                                        "isRispostaLibera": isRispostaLibera});
            });
    //controllo se l'utente ha risposto a tutte le domande, in caso contrario visualizzo il msg
    if(arrayRisposte.length<this.detailQuiz['body'].length) {
        this.quizNonCompleto();
        return;
    }

    Swal.fire({
        icon: 'question',
        title: 'Sei sicuro?',
        text: 'vuoi confermare e procedere con la verifica del TEST?',
        showCancelButton: true,
        confirmButtonColor: 'var(--mainColor)',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {

            document.getElementById('btnTest').remove();

            this.crudService.api_verifica_quiz(arrayRisposte).subscribe(product => {
                //console.log(product);
                let corrette=product['corrette'];
                let nonCorrette=product['non_corrette'];
                let numeroDomande=product['numero_domande'];
                let sogliaMinimaPercentuale=product['soglia_minima_percentuale'];
                let sogliaMinimaDomande=product['soglia_minima_domande'];
                let sogliaCorrettePercentuale=product['soglia_corrette_percentuale'];
                if(corrette>=sogliaMinimaDomande) {
                    if(this.detailUser['canDownloadAttestato']==0) {
                        //test superato
                        this.quizSuperato(corrette, numeroDomande);
                        return;
                    }

                    //genero l'attestato
                    this.crudService.api_GeneraAttestato(arrayDetail).subscribe(product => {
                        //console.log(product);
                        let nomeCorsista=product['nome_corsista'];
                        let data_nascita=product['data_nascita'];
                        let provincia_nascita=product['provincia_nascita'];
                        let comune_nascita=product['comune_nascita'];
                        let data_attestato=product['data_attestato'];
                        let cf=product['cf'];
                        let codiceAteco=product['codice_ateco'];
                        let isPresent=product['isPresent'];
                        let attestato=product['attestato'];
                        let dataStart=product['dataStart'];
                        if(isPresent.hasOwnProperty('id_certificato')) {
                            window.open(environment.urlAttestati+isPresent.file);

                            setTimeout(() => {
                                //test superato
                                this.quizSuperato(corrette, numeroDomande);
                            }, 250);
                        }
                        else {
                            if(attestato) {
                                var detail='?id_corso='+idCorso+'&id_corso_svolgimento='+idCorsoSvolgimento+'&id_corsista='+idCorsista+'&nome_corsista='+nomeCorsista+'&data_nascita='+data_nascita+'&provincia_nascita='+provincia_nascita+'&comune_nascita='+comune_nascita+'&data_attestato='+data_attestato+'&dataStart='+dataStart+'&cf='+cf+'&protocollo='+this.protocollo+'&mansione='+this.mansione+'&codiceAteco='+codiceAteco;
                                window.open(environment.urlAttestatiDaCreare+attestato+".php"+detail);

                                setTimeout(() => {
                                    //test superato
                                    this.quizSuperato(corrette, numeroDomande);
                                }, 250);
                            }
                            else {
                                Swal.fire({
                                    title: 'Errore!',
                                    text: "non è stasto creato l\'attestato .pdf, non posso procedere alla generazione dell\'attestato",
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Risolvi',
                                    cancelButtonText: 'Annulla'
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                    }
                                })
                            }
                        }
                    });
                }
                else {
                    //test non superato
                    this.quizNonSuperato(corrette, nonCorrette);
                }

            });
        }
    })


  }

  quizSuperato(risposteCorrette, numeroTotaleDomande) {
    Swal.fire({
        title:'Complimenti!',
        text: 'hai superato il test con il numero di '+risposteCorrette+' risposte corrette su un totale di '+numeroTotaleDomande+' domande',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }

  quizNonSuperato(risposteCorrette, risposteNonCorrette) {
    Swal.fire({
        title:'Test Fallito!',
        text: 'non hai superato il test, hai sbagliato '+risposteNonCorrette+' risposte, hai risposto in modo esatto solo a '+risposteCorrette+' domande.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Riprova',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            location.reload();
        }
    });
  }

  quizNonCompleto() {
    Swal.fire({
        title:'Test Incompleto!',
        text: 'devi rispondere a tutte le domande, verifica',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Riprova',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  countTimer() {
    console.log(this.tempoTrascorso);

    var myVideo: any = document.getElementById("myVideo");
    if(myVideo==null)
        return;

    var precedenteTempo=this.tempoTrascorso;
    this.tempoTrascorso=parseInt(myVideo.currentTime);

    if(parseFloat(this.arrayLive['limita_moduloUC_durata'])>0) {
        if(this.limiteGiorno>0) {
            if(precedenteTempo!=this.tempoTrascorso)
                this.aggiornaTempoLimite();
        }
    }
 }

 countTimerNoLimite() {
    this.aggiornaTempoLimite();
 }

 showTimecheck() {
    let timerInterval;

    var now = new Date();
    var strDateTime = [[this.AddZero(now.getFullYear()),
        this.AddZero(now.getMonth() + 1),
        now.getDate()].join("-"),
        [this.AddZero(now.getHours()),
        this.AddZero(now.getMinutes()),
        this.AddZero(now.getSeconds())].join(":")].join(" ");

    Swal.fire({
        allowOutsideClick: false,
        title: 'Controllo presenza',
        icon: 'warning',
        html: 'clicca sul pulsante <span class="fw-bolder">"Si, sono presente!"</span> per conferma che sei presente online. Tempo residuo <b></b> secondi',
        timer: 60000,
        timerProgressBar: true,
        showCancelButton: false,
        confirmButtonText: 'Si, sono presente!',
        cancelButtonText: 'No, cancel!',
        confirmButtonColor: 'var(--mainColor)',
        didOpen: () => {
            clearInterval(this.timerHandleCheck);
            window.focus();

            //Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = parseInt((Swal.getTimerLeft()/1000).toFixed(0)).toString();
            }, 100)
        },
        willClose: () => {
            this.timeCheck=this.timeCheckBackup;
            this.timerHandleCheck=setInterval(() => {
                this.countTimeCheck();
            }, 1000);
        }
    }).then((result) => {
        if (result.isConfirmed) {
            //sono presente
            this.salvaCheckTime(strDateTime, 1);
        }
        if (result.dismiss === Swal.DismissReason.timer) {
            //tempo check scaduto
            this.salvaCheckTime(strDateTime, 0);
        }
    })
 }

 salvaCheckTime(dateTimeInizio, isPresent) {
    var now = new Date();
    var strDateTime = [[this.AddZero(now.getFullYear()),
        this.AddZero(now.getMonth() + 1),
        now.getDate()].join("-"),
        [this.AddZero(now.getHours()),
        this.AddZero(now.getMinutes()),
        this.AddZero(now.getSeconds())].join(":")].join(" ");

    let idCorsoSvolgimento=(this.arrayLive['id_corso_svolgimento']);
    let idCorsista=this.detailUser['id_corsista'];
    this.crudService.salvaCheckTime(dateTimeInizio, strDateTime, isPresent, idCorsoSvolgimento, idCorsista, this.productID).subscribe(product => {
        //console.log(product);
    });
 }

 AddZero(num) {
    return (num >= 0 && num < 10) ? "0" + num : num + "";
}

 countTimeCheck() {
    if(this.timeCheck<=1) {
        clearInterval(this.timerHandleCheck);
        this.showTimecheck();
    }
    this.timeCheck-=1;
 }

 countTimerAula() {
    if(this.limiteGiornoAula>0) {
        this.aggiornaTempoLimiteAula();
    }
 }

  startTimer(idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo) {
    this.arraySaveDatiUC_Tempo['idCorso']=idCorso;
    this.arraySaveDatiUC_Tempo['idPartner']=idPartner;
    this.arraySaveDatiUC_Tempo['idModuloBody']=id_modulo_body;
    this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']=id_modulo_body_paragrafo;
    this.arraySaveDatiUC_Tempo['idCorsista']=this.detailUser['id_corsista'];

    this.timerHandle=setInterval(() => {
        this.countTimer();
    }, 1000);

    this.initVideoPlayer();
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d / 60) % 60);
    var s = (d % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s") : "";
    return hDisplay + mDisplay + sDisplay;
}

  secondToMinute(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        //ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        mins=(hrs*60)+mins;
    }

    ret += "" + mins + "." + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

  playVideo(videoFile, titolo, idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo, minuteStart, tempoRestante, isLocked) {
    //console.log((parseFloat(minuteStart)+(this.tempoTrascorso/100)));
    //minuteStart=Math.round(((parseFloat(minuteStart)+(this.tempoTrascorso/100)))).toFixed(2).toString();



    this.crudService.getTimeVideoVisualizzato(this.detailUser['id_corsista'], idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo, this.reportForData).subscribe(responseData => {
        minuteStart=responseData;

        if(minuteStart>=tempoRestante)
            minuteStart=0;


        videoFile=videoFile.trim();
        this.entraInAula();

        if(minuteStart!=0 && minuteStart!="") {
            var ar=minuteStart.split(".");
            if(ar.length) {
                var minuti=ar[0];
                var secondi=0;
                if(ar.length>1)
                    secondi=ar[1];

                //converto i minuti in secondi
                minuteStart=(minuti*60)+(secondi*1);
            }
        }

        //imposto il tempo iniziale da dove ho lasciato quando il video corso è stato chiuso
        //this.tempoTrascorso=(minuteStart*1);
        this.timeStartVideo=(minuteStart*1);
        console.log("K."+this.timeStartVideo);

        Swal.fire({
            html: ' <img style="max-height:100px" src="'+this.logoPartner+'"><br><hr>\
                    <span style="font-weight:800; font-size:18px; color:var(--blackColor)">'+titolo+'</span>\
                   <video #myVideo height="419px" width="100%" style="margin-top:15px;" oncontextmenu="return false;" id="myVideo" autoplay playsinline controls controlsList="nodownload noplaybackrate" src="'+videoFile+'#t='+(this.timeStartVideo)+'"></video>\
                   <style>\
                   video::-webkit-media-controls-timeline {\
                        display: none;\
                    }</style>',
            width: '800px',
            heightAuto: false,
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Chiudi / Ritorna al corso',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                clearInterval(this.timerHandle);
                this.chiudiVideo();
            }
        });


        /*
        setTimeout(() => {
            // Imposta il tempo di inizio a 10 secondi
            var myVideo: any = document.getElementById("myVideo");

            myVideo.addEventListener('loadedmetadata', function() {
                setTimeout(() => {
                    myVideo.pause();
                    myVideo.currentTime = 50;
                    myVideo.play();
                    alert("2");

                }, 5000);
            });
            alert("1");
        }, 2000);
        */

        setTimeout(() => {
            //avvio il timer
            this.startTimer(idCorso, idPartner, id_modulo_body, id_modulo_body_paragrafo);
        }, 250);
    });
  }

  openRoom() {
   this.showMeet=true;
  }

  initVideoPlayer() {
    var myVideo: any = document.getElementById("myVideo");

    var supposedCurrentTime = 0;
    myVideo.addEventListener('timeupdate', function() {
        if (!myVideo.seeking) {
            supposedCurrentTime = myVideo.currentTime;
        }
    });

    myVideo.addEventListener('pause', function() {
        //console.log("pause");
    });

    myVideo.addEventListener('playing', function() {
        //console.log("play");
    });

    //myVideo.currentTime = 50;
    myVideo.removeEventListener('seeking', this.onSeeking());

    myVideo.addEventListener('loadedmetadata', () => {
        setTimeout(() => {
            myVideo.addEventListener('seeking', function() {
                // guard agains infinite recursion:
                // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
                var delta = myVideo.currentTime - supposedCurrentTime;
                // delta = Math.abs(delta); // disable seeking previous content if you want
                if (delta > 0.01) {
                    myVideo.currentTime = supposedCurrentTime;
                }
                else if(delta < 0) {
                    myVideo.currentTime = supposedCurrentTime;
                }
            });
        }, 1000);

    }, 5000);



  }

  onSeeking () {

  }

  dettaglioConnessioniVideo(idCorso, idCorsoSvolgimento, data, idCorsista) {
    if(!idCorso || !idCorsista || !data)
        return;

    var arrayDetail={'idCorso': idCorso, 'idCorsista': idCorsista, 'dataDay': data};

    this.crudService.api_getDetailTempoConnessioneDay(arrayDetail).subscribe(responseData => {
        var arrayData: any=[];
        this.arrayConnessioniVideo.splice(0);
        arrayData=responseData;
        arrayData.forEach(element => {
            this.arrayConnessioniVideo.push({'titoloUC': element['titoloUC'], 'paragrafo': element['paragrafo'], 'titolo': element['titolo'], 'durata': element['durataFormattata']});
        });

        this.showDetailVideoConnessioni(this.arrayConnessioniVideo, idCorsoSvolgimento);
    });
  }

  chiudiVideo() {
    console.log("aaa."+this.tempoTrascorso+"---"+this.timeStartVideo);
    if((this.tempoTrascorso<=0) || this.tempoTrascorso==this.timeStartVideo)
        return;

    this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso);
    if(this.tempoTrascorso>parseFloat(this.timeStartVideo))
        this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso-parseFloat(this.timeStartVideo));
    this.arraySaveDatiUC_Tempo['idCorsoSvolgimento']=this.elencoDataCorso[0]['id_corso_svolgimento'];
    this.arraySaveDatiUC_Tempo['isInLive']=this.reportForData;
    this.arraySaveDatiUC_Tempo['isPDF']='0';

    if(this.isFAD_Always==false) {
        this.crudService.salveTempoTrascorsoVideo(this.arraySaveDatiUC_Tempo).subscribe(responseData => {
            let totaleAggiornato=(parseFloat(this.timeStartVideo)+(parseFloat(this.secondToMinute(this.tempoTrascorso))*100)).toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).innerHTML=responseData.toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).classList.remove("d-none");
            this.tempoTrascorso=0;
            this.videoTempoAggiornato();

            var isHaveElencoDate=1;
            if(this.elencoDataCorso.length<=1)
                isHaveElencoDate=0;

            this.crudService.api_get_refreshModuloComplete(this.idCorsoGlobal, this.detailUser.id_corsista, isHaveElencoDate).subscribe(response => {
                var indexModulo=0;

                console.log(response);

                this.moduliUC.forEach(element => {
                    console.log(indexModulo);
                    if(element['isComplete']!=response[indexModulo]['isComplete']) {
                        if(this.moduliUC.length>=(indexModulo++)) {
                            console.log(element['isComplete']+"---"+response[indexModulo]['isComplete']+"<br>");
                            this.nuovoModuloSloccato(this.moduliUC[indexModulo++]['titolo']);
                            return;
                        }
                    }
                    indexModulo++;
                });
            });
        });
    }
  }

  chiudiVideoLimiteRaggiunto() {
    this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso);
    if(this.tempoTrascorso>parseFloat(this.timeStartVideo))
        this.arraySaveDatiUC_Tempo['tempoTrascorso']=this.secondToMinute(this.tempoTrascorso-parseFloat(this.timeStartVideo));
    this.arraySaveDatiUC_Tempo['idCorsoSvolgimento']=this.elencoDataCorso[0]['id_corso_svolgimento'];
    this.arraySaveDatiUC_Tempo['isInLive']=this.reportForData;
    this.arraySaveDatiUC_Tempo['isPDF']='0';

    if(this.isFAD_Always==false) {
        this.crudService.salveTempoTrascorsoVideo(this.arraySaveDatiUC_Tempo).subscribe(responseData => {
            //console.log(responseData);
            //location.reload();
            let totaleAggiornato=(parseFloat(this.timeStartVideo)+(parseFloat(this.secondToMinute(this.tempoTrascorso))*100)).toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).innerHTML=responseData.toString();
            document.getElementById("video_"+this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']).classList.remove("d-none");
            this.tempoTrascorso=0;
            this.limiteRaggiunto(1);
        });
    }
  }

  downloadPDF_ModuloUC(idModuloBody, idModuloBodyParagrafo) {
    this.arraySaveDatiUC_Tempo['tempoTrascorso']='';
    this.arraySaveDatiUC_Tempo['idCorso']=this.idCorsoGlobal;
    this.arraySaveDatiUC_Tempo['idCorsista']=this.detailUser['id_corsista'];
    this.arraySaveDatiUC_Tempo['idPartner']=this.detailUser['id_partner'];
    this.arraySaveDatiUC_Tempo['idModuloBody']=idModuloBody;
    this.arraySaveDatiUC_Tempo['idModuloBodyParagrafo']=idModuloBodyParagrafo;
    this.arraySaveDatiUC_Tempo['idCorsoSvolgimento']=this.elencoDataCorso[0]['id_corso_svolgimento'];
    this.arraySaveDatiUC_Tempo['isInLive']=this.reportForData;
    this.arraySaveDatiUC_Tempo['isPDF']='1';

    this.crudService.salveTempoTrascorsoVideo(this.arraySaveDatiUC_Tempo).subscribe(responseData => {
        this.tempoTrascorso=0;
    });
  }

  checkUserIsEnabled(idCorso, idCorsista) {
    let limiteConnessioneEffettuato=0;

    this.lockModuliUC=1;
    this.crudService.api_get_ElencoAulaCorsoTag(idCorso, idCorsista).subscribe(responseData => {
        if(responseData[0]) {
            //console.log(responseData);

            this.isEnabledUser=true;
            this.elencoDataCorso=responseData;

            this.arrayLive = this.elencoDataCorso[0];

            //variabile sentinella, controlla se nello stesso giorno ho più eventi
            var isInLiveTMP=0;
            this.elencoDataCorso.forEach(element => {
                //se nello stesso giorno non ho più eventi allora valuto la condizione
                //altrimenti significa che ho già trovato l'evento del giorno con la live in corso
                if(isInLiveTMP==0) {
                    if(element['isToday']==1) {
                        this.isInLive=element['isLive'];
                        this.protocollo=element['protocollo'];
                        this.limita_moduloUC=element['limita_moduloUC'];
                        this.isInLiveLinkAula=element['link_aula'];
                        this.arrayLive=element;
                        if(this.elencoDataCorso.length<=1) {
                            if(element['isLive']==3)
                                this.lockModuliUC=0;
                        }
                        //controllo se l'evento è in live
                        if(element['isLive']==1)
                            isInLiveTMP=1;
                        }
                    }
            });

            //mi trovo l'evento live ed aggiorno i riferimenti
            var isOnlyDAD=1;
            var isEventDaySelected=0;
            this.elencoDataCorso.forEach(element => {
                    //console.log(element);

                    //mi controllo se l'ente ha scelto di far vedere SEMPRE la FAD in ogni giorno (oltre a quelli in calendario)
                    //viene tracciata però SOLO quella in calendario
                    //controllo se la FAD deve essere visualizzata anche nell'evento del giorno
                    if((element['limita_moduloUC']==1) && ((element['isLive']==1 || element['isLive']==2))) {
                        this.isFAD_Always=false;
                        isEventDaySelected=1;
                    }
                    else if((element['limita_moduloUC']==0) && (element['isFADAlways']==1) && isEventDaySelected==0) {
                            this.isFAD_Always=true;
                    }
                    else if(element['isFADAlways']==1 && isEventDaySelected==0)
                        this.isFAD_Always=true;


                    if(element['limita_moduloUC']==1)
                        isOnlyDAD=0;

                    if(this.lockModuliUC==1) {
                        if((element['limita_moduloUC']==1) && (!(element['isLive']==1))) {
                            this.lockModuliUC=1;
                        }
                    }
                    if(element['isLive']==5 || element['isLive']==2) {
                        this.reportForData=1;
                        this.limita_moduloUC=element['limita_moduloUC'];
                    }

                    if(element['isLive']==1) {
                        //console.log(element);
                        this.reportForData=1;


                        if(element['limita_moduloUC']==1) {
                            this.lockModuliUC=0;
                        }

                        this.limiteGiorno=0;
                        this.limiteGiornoAula=0;

                        //mi controllo se il limite giornaliero è stato raggiunto
                        if(element['limita_moduloUC']==1) {

                            //converto da minuti a secondi
                            if(parseFloat(element['limita_moduloUC_durata'])>0) {
                                this.limiteGiorno=(element['limita_moduloUC_durata']*60);
                                if(parseFloat(element['totaleConnessioneGiorno'])) {
                                    limiteConnessioneEffettuato=(parseFloat(element['totaleConnessioneGiorno']));
                                }

                                /*
                                if(parseFloat(element['totaleConnessioneGiorno'])>=parseFloat(element['limita_moduloUC_durata'])) {
                                    this.isLimiteRaggiunto=true;
                                }
                                */

                                if(limiteConnessioneEffettuato>=this.limiteGiorno) {
                                    this.isLimiteRaggiunto=true;
                                    this.lockModuliUC=1;
                                }

                                this.tempoRestanteLimite=(this.limiteGiorno-limiteConnessioneEffettuato);
                                this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);
                                this.tempoRestanteSessione=element['fineSeconds'];
                                this.labelTempoRestanteSessione=this.secondsToHms(this.tempoRestanteSessione);

                                this.timerHandle=setInterval(() => {
                                    this.aggiornaTempoSessione();
                                }, 1000);
                            }
                            else {
                                this.tempoRestanteLimite=element['fineSeconds'];
                                this.limiteGiorno=element['fineSeconds'];
                                this.isLimiteRaggiunto=false;
                                this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);
                                this.timerHandle=setInterval(() => {
                                    this.countTimerNoLimite();
                                }, 1000);
                            }

                        } else if((parseFloat(element['limita_aula_durata'])>0) && (element['limita_moduloUC']==0)) {
                            //converto da minuti a secondi
                            this.limiteGiornoAula=(element['limita_aula_durata']*60);
                            if(parseFloat(element['totaleConnessioneGiornoAula'])) {
                                limiteConnessioneEffettuato=(parseFloat(element['totaleConnessioneGiornoAula']));
                            }

                            this.tempoRestanteLimite=(this.limiteGiornoAula-limiteConnessioneEffettuato);
                            this.labelTempoRestanteLimite=this.secondsToHms(this.tempoRestanteLimite);

                            if(limiteConnessioneEffettuato>=this.limiteGiornoAula) {
                                this.isLimiteRaggiunto=true;
                            }
                            else {
                                this.timerHandle=setInterval(() => {
                                    this.countTimerAula();
                                }, 1000);
                            }
                        }

                        //element['limita_moduloUC']=this.lockModuliUC;
                        this.isInLive=element['isLive'];
                        this.limita_moduloUC=element['limita_moduloUC'];
                        this.isInLiveLinkAula=element['link_aula'];

                        /*
                        if(element['isToday']==1) {
                            console.log("aaaaaa");
                            this.arrayLive=element;
                            console.log("okkkk."+this.arrayLive);
                        }
                        */

                    }
            });
        }

        this.loadDetailsCorso(this.productID, isOnlyDAD);

    });

  }

  changeBriciola() {
      this.detallCorso.titolo=this.cntClick+" Provami...";
      this.cntClick++;
  }

  limiteRaggiunto(isRefresh) {
    Swal.fire({
        title:'Limite Raggiunto!',
        text: 'hai raggiunto il limite giornaliero per la visualizzazione dei video, consula la lista degli eventi (\"Elenco Date\").',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            if(isRefresh)
                location.reload();
        }
    });
  }

  videoTempoAggiornato() {
    Swal.fire({
        title:'Aggiornato!',
        text: 'il tempo di visualizzazione del video è stato aggiornato.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Grazie',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {

        }
    });
  }

  nuovoModuloSloccato(titolo) {
    Swal.fire({
        title:'Nuovo modulo sbloccato',
        html: '<b>'+titolo+"</b><br>è pronto per essere visualizzato.",
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#6993FF',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Chiudi',
        allowOutsideClick: false,
     }).then((result) => {
        if (result.isConfirmed) {
            window.location.reload();
        }
    });
  }

  showDetailVideoConnessioni(arrayElement, idCorsoSvolgimento) {
    var elements="";
    var titleUC="";
    var paragrafo="";

    //console.log(arrayElement);
    arrayElement.forEach(element => {
        if(titleUC!=element['titoloUC']) {
            titleUC=element['titoloUC'];
            elements+="<h6 class='fw-bolder pt-3 text-uppercase' style='color:var(--mainColor)'>"+titleUC+"</h6>";
        }
        if(paragrafo!=element['paragrafo']) {
            paragrafo=element['paragrafo'];
            elements+="<h6 class='fw-bolder pt-2' style='color:var(--bs-black)'>"+paragrafo+"</h6>";
        }
        elements+='<div class="pb-1 row">\
                        <span class="col-lg-2 fw-bold p-0" style="text-align:right; color: #2dbbc4; font-size: 11pt;">'+element['durata']+'</span>\
                        <span class="col-lg-9 text-lowercase">'+element['titolo']+'</span>\
                    </div>';
    });

    if(arrayElement.length>0) {
        var txt='<div class="">'+elements+'</div>';
        document.getElementById('accordion_'+idCorsoSvolgimento).innerHTML = txt;
    }
    else {
        if(document.getElementById('accordion_'+idCorsoSvolgimento))
            document.getElementById('accordion_'+idCorsoSvolgimento).innerHTML = "nessuna connessione effettuata";
    }

  }

  apriTestValutazione(indexSelected) {
    this.moduloUC_TestValutazioneSelected=this.moduloUC_TestValutazione[indexSelected];
    if(this.moduloUC_TestValutazione[indexSelected])
        this.showTestValutazione=true;
  }

  stampaTestValutazione(idQuizHeader) {
    let idCorsista=this.detailUser['id_corsista'];

    window.open(this.urlStampa+"report-test-valutazione.php?idCorsista="+idCorsista+"&idCorso="+this.idCorsoGlobal+"&idQuizHeader="+idQuizHeader+"&isTestValutazione=1");
  }

  chiudiTestValutazione() {
    this.showTestValutazione=false;
  }

  verificaTestValutazione(idQuizHeader) {
    var arrayQuiz=this.GetAllSelectRadioButtons();
    var arrayRisposte=new Array();

    let idCorsista=this.detailUser['id_corsista'];
    let idCorsoSvolgimento=this.arrayLive['id_corso_svolgimento'];
    let idCorso=this.detailQuiz['id_corso'];

    arrayQuiz.forEach((element) => {
        var domanda=element.name;
        var risposta=element.value;
        arrayRisposte.push({"id_corso":idCorso,
                            "id_header":idQuizHeader,
                            "id_corsista":idCorsista,
                            "id_corso_svolgimento":idCorsoSvolgimento,
                            "id_domanda":domanda,
                            "id_risposta":risposta,
                            "isTestValutazione": '1',
                            "isRispostaLibera": '0'});
    });

     //controllo se l'utente ha risposto a tutte le domande, in caso contrario visualizzo il msg
     if(arrayRisposte.length<this.moduloUC_TestValutazioneSelected['quizBody'].length) {
       this.quizNonCompleto();
        return;
    }

    this.crudService.api_verifica_quiz(arrayRisposte).subscribe(product => {
        let corrette=product['corrette'];
        let nonCorrette=product['non_corrette'];
        let numeroDomande=product['numero_domande'];
        let sogliaMinimaPercentuale=product['soglia_minima_percentuale'];
        let sogliaMinimaDomande=product['soglia_minima_domande'];
        let sogliaCorrettePercentuale=product['soglia_corrette_percentuale'];
        if(corrette>=sogliaMinimaDomande) {
            //test superato
            this.quizSuperato(corrette, numeroDomande);
        }
        else {
            //test non superato
            this.quizNonSuperato(corrette, nonCorrette);
        }

    });
  }

  downloadAttestato(idCorsista, idCorso, idCorsoSvolgimento) {
    //mi serve per la gestione della generazione dell'attestato
    var arrayDetail={'id_corso': idCorso, 'id_corsista': idCorsista, 'id_corso_svolgimento': idCorsoSvolgimento};

    //genero l'attestato
    this.crudService.api_GeneraAttestato(arrayDetail).subscribe(product => {
        //console.log(product);
        let nomeCorsista=product['nome_corsista'];
        let data_nascita=product['data_nascita'];
        let provincia_nascita=product['provincia_nascita'];
        let comune_nascita=product['comune_nascita'];
        let data_attestato=product['data_attestato'];
        let cf=product['cf'];
        let codiceAteco=product['codice_ateco'];
        let isPresent=product['isPresent'];
        let attestato=product['attestato'];
        let dataStart=product['dataStart'];
        if(isPresent.hasOwnProperty('id_certificato')) {
            window.open(environment.urlAttestati+isPresent.file);
        }
        else {
            if(attestato) {
                var detail='?id_corso='+idCorso+'&id_corso_svolgimento='+idCorsoSvolgimento+'&id_corsista='+idCorsista+'&nome_corsista='+nomeCorsista+'&data_nascita='+data_nascita+'&provincia_nascita='+provincia_nascita+'&comune_nascita='+comune_nascita+'&data_attestato='+data_attestato+'&dataStart='+dataStart+'&cf='+cf+'&protocollo='+this.protocollo+'&mansione='+this.mansione+'&codiceAteco='+codiceAteco;
                window.open(environment.urlAttestatiDaCreare+attestato+".php"+detail);
            }
            else {
                Swal.fire({
                    title: 'Errore!',
                    text: "non è stasto creato l\'attestato .pdf, non posso procedere alla generazione dell\'attestato",
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Risolvi',
                    cancelButtonText: 'Annulla'
                  }).then((result) => {
                    if (result.isConfirmed) {
                    }
                })
            }
        }
    });
  }

  rimuoviEsameDallaSessione(idEsameScelto) {

    Swal.fire({
        icon: 'question',
        title: 'Sei sicuro?',
        text: 'vuoi eliminare l\'esame dalla sessione?',
        showCancelButton: true,
        confirmButtonColor: 'var(--mainColor)',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            var arrayDetail={'idEsameScelto': idEsameScelto};

            this.crudService.api_deleteEsamePrenotato(arrayDetail).subscribe(product => {
                console.log(product);
                if(product==1) {
                    Swal.fire({
                        title:'Eliminato',
                        text: 'esame eliminato con successo dalla sessione d\'esame.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#6993FF',
                        cancelButtonColor: '#dadada',
                        confirmButtonText: 'Grazie',
                        allowOutsideClick: false,
                     }).then((result) => {
                        if (result.isConfirmed) {
                            document.getElementById("idEsameScelto_"+idEsameScelto).remove();
                        }
                    });
                }
            });

        }
    })
  }

  rimuoviDalleSessioneEsame(idEsameCorsisti) {
    Swal.fire({
        icon: 'question',
        title: 'Sei sicuro?',
        text: 'ti vuoi togliere dalla sessione d\'esame selezionata?',
        showCancelButton: true,
        confirmButtonColor: 'var(--mainColor)',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            var arrayDetail={'idEsameCorsisti': idEsameCorsisti};

            this.crudService.api_eliminaCorsistaSessioneEsame(arrayDetail).subscribe(product => {
                console.log(product);
                if(product==1) {
                    Swal.fire({
                        title:'Eliminato',
                        text: 'sei stato eliminato dalla sessione d\'esame.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#6993FF',
                        cancelButtonColor: '#dadada',
                        confirmButtonText: 'Grazie',
                        allowOutsideClick: false,
                     }).then((result) => {
                        if (result.isConfirmed) {
                            location.reload();
                        }
                    });
                }
            });

        }
    })
  }

  prenotaSessioneEsame(idEsameBody, timeSessione, havePrenotato, dataSessione) {
    if(havePrenotato!=0) {
        this.rimuoviDalleSessioneEsame(havePrenotato);
        return;
    }

    var arrayDetail={'id_corso': this.idCorsoGlobal, 'id_corsista': this.detailUser.id_corsista, 'dataSessioneEsame': dataSessione.data_sessione};
    this.crudService.api_getElencoEsamiForCorsoCorsista(arrayDetail).subscribe(product => {
        console.log(product);
    });

    return;


    Swal.fire({
        icon: 'question',
        title: 'Sei sicuro?',
        text: 'vuoi confermare la sessione d\'esame delle '+timeSessione+' selezionata?',
        showCancelButton: true,
        confirmButtonColor: 'var(--mainColor)',
        cancelButtonColor: '#dadada',
        confirmButtonText: 'Si, confermo!',
        cancelButtonText: 'Annulla',
        width: '400px',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
            var arrayDetail={'idEsameBody': idEsameBody, 'id_corsista': this.detailUser.id_corsista, 'id_corso': this.idCorsoGlobal};
            this.crudService.api_prenotaSessioneEsame(arrayDetail).subscribe(product => {
                if(product==1) {
                    Swal.fire({
                        title:'Prenotato',
                        text: 'hai prenotato con successo la tua sessione d\'esame.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#6993FF',
                        cancelButtonColor: '#dadada',
                        confirmButtonText: 'Chiudi',
                        allowOutsideClick: false,
                     }).then((result) => {
                        if (result.isConfirmed) {
                            location.reload();
                        }
                    });
                }
            });

        }
    })
  }

  slotCompleto() {
    Swal.fire({
        title: 'Errore!',
        text: "non ci sono posti disponibili, lo slot risulta al completo.",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Chiudi',
        cancelButtonText: 'Annulla'
      }).then((result) => {
        if (result.isConfirmed) {
        }
    })
  }

  pinEsameErrato() {
    Swal.fire({
        title: 'Errore!',
        text: "il PIN ESAME inserito risulta non corretto. Riprova",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Chiudi',
        cancelButtonText: 'Annulla'
      }).then((result) => {
        if (result.isConfirmed) {
        }
    })
  }

  pinEsameCorretto() {
    Swal.fire({
        title: 'Corretto!',
        text: "il PIN ESAME risulta corretto, puoi procedere con l'esame.",
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Chiudi',
        cancelButtonText: 'Annulla'
    }).then((result) => {
        if (result.isConfirmed) {
        }
    })
  }

  sessioneBloccataGiaPrenotata() {
    Swal.fire({
        title: 'Bloccata!',
        text: "hai già prenotato un'altra sessione d'esame nella stessa giornata, non puoi prenotare più sessioni nella stessa giornata.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Chiudi',
        cancelButtonText: 'Annulla'
    }).then((result) => {
        if (result.isConfirmed) {
        }
    })
  }

  showEsamePINEsame(pinEsameUser, idEsameBody) {
    Swal.fire({
        allowOutsideClick: false,
        title: 'PIN ESAME',
        icon: 'warning',
        html: 'per procedere con la visualizzazione dell\'esame, inserisci il PIN ESAME e clicca su verifica\
                <div class="form-group">\
                    <input type="text" id="pinEsame" class="form-control text-center mt-2" placeholder="inserisci il PIN">\
                </div>',
        showCancelButton: true,
        confirmButtonText: 'Verifica PIN',
        cancelButtonText: 'Annulla',
        confirmButtonColor: 'var(--mainColor)',
        didOpen: () => {

        },
        willClose: () => {

        }
    }).then((result) => {
        if (result.isConfirmed) {
            var pinEsame = document.getElementById('pinEsame') as HTMLInputElement;
            if(pinEsame.value==pinEsameUser) {
                this.showEsameDiretto=true;
                var arrayDetail={'idCorsista': this.detailUser.id_corsista, 'idEsameBody': idEsameBody, 'tipologia': 'pin_esame'};

                //mi registro l'orario e data dell'inizio esame
                this.crudService.set_dateTimeInizioEsame(arrayDetail).subscribe(response => {
                    //mi registro nel report, l'ingresso nell'esame con il pin
                    this.crudService.set_entraInMeetEsame(arrayDetail).subscribe(response => {
                        this.timerHandle=setInterval(() => {
                            this.aggiornaTempoLimiteTEST();
                        }, 1000);
                        this.pinEsameCorretto();
                    });
                });
            }
            else {
                this.pinEsameErrato();
            }
        }
    })
  }

}
