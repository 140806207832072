<div class="funfacts-and-feedback-area ptb-100" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">cosa dicono di noi</span>
                    <h2>Raggiungi i tuoi obiettivi con AUGE</h2>
                    <p>Puoi studiare quando e dove vuoi. Abbiamo studenti in tutta Italia e una reputazione globale come pionieri nel campo dell'apprendimento flessibile. Il nostro insegnamento significa anche che, se viaggi spesso o devi trasferirti, puoi continuare a seguire i corsi ovunque tu vada.</p>
                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>Mi hanno seguita nel mio percorso di specializzazione. Continuerò a rivolgermi a AUGE per ulteriori certificazioni!</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>Cristina</h3>
                                            <span class="fw-bolder">Corso O.S.S</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>Ottima organizzazione e competenza, sempre gentili e disponibili. Professori preparati e chiarissimi nelle spiegazioni.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>Rosa</h3>
                                            <span class="fw-bolder">Corso OPI</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>Ho effettuato dei corsi tramite le videolezioni. Molto pratico come metodo perché è possibile rivedere le lezioni ogni volta che si desidera.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>Giovanni</h3>
                                            <span class="fw-bolder">Corso di Dattilografia</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <p>La piattaforma è intuitiva, e ho avuto la possibilità di interfacciarmi sempre con consulente di AUGE per ogni difficoltà!</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>Raffaele</h3>
                                            <span class="fw-bolder">Corso operatore socio assistenziale (OSA)</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="feedback-info">
                        <p>Non sei ancora registrato?​ <a routerLink="/contatti">Registrati adesso</a></p>
                        <p>Password dimenticata?​ <a routerLink="/contatti">Reset Password</a></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="4000">00</span>+</h3>
                                <p>Studenti Iscritti</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="3000">00</span>+</h3>
                                <p>Classi Completate</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="92">00</span>%</h3>
                                <p>Studenti con riscontri lavorativi</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="500">00</span>+</h3>
                                <p>Corsi con punteggi per concorsi</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-video></app-video>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
