<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-none"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:0px">
                        Ente di alta formazione Universitaria privato regolato da statuto per sviluppare gli studi in varie branche del sapere che opera in conformità agli standard di cui all’art.6 del Dlgs 16 gennaio 2013.
                        Alta Formazione in ambito Giuridico-Economico con uno sguardo globale e una matrice culturale profondamente Internazionale.
                        La nostra esperienza formativa si basa su un modello semplice ed efficace: uniamo la teoria alla pratica e al sapere portato in aula dai professionisti del mondo del lavoro. Insegniamo ai nostri studenti ad essere un passo avanti, nel presente.
                        Siamo una scuola inclusiva e transdisciplinare che utilizza l’informazione come linguaggio universale per il cambiamento.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/accademiauge/?locale=it_IT" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://instagram.com/accademiauniversitaria" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 d-none">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://discentya.accademiauge.cloud/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://discentya.accademiauge.cloud/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Circonvallazione Clodia, 163/167<br>00195 Roma (Italia)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390692939204">Telefono: (+39) 06-92939204 </a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@accademiauge.it">info@accademiauge.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+390692939204">Lunedì-Venerdì: 09:00÷18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2023 AUGE - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a class="d-none" routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
